<template>
  <TModal
    title="Create Wallet"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :key="key"
    :creating="disabled"
  >
    <SSelectCustomer
      v-if="user"
      label="Customer"
      class="mb-3"
      @update:value="data.walletable_id = $event"
    />
    <SSelectSupplier
      v-else
      label="Supplier"
      class="mb-3"
      @update:value="data.walletable_id = $event"
    />
    <SSelectCurrency
      label="Currency"
      class="mb-3"
      :prepend="[]"
      :value.sync="data.currency_id"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      data: {},
      key: 'walletModal',
      disabled: false
    };
  },
  computed: {
    store() {
      return this.user ? "wallets_users" : "wallets_suppliers"
    },
  },
  methods: {
    create() {
      this.disabled = true
      this.$store
        .dispatch(`accounting.${this.store}.create`, this.data)
        .then(() => {
          this.data = {};
          this.key = this.lodash.resetKey('walletModal')
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.disabled = false
        })
    },
  },
};
</script>
