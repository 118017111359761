<template>
  <div>
    <TTableAdvance
      :items="wallets"
      :fields="fields"
      store="accounting.wallets_suppliers"
      @click-clear-filter="clearFilter"
      resource="/accounting/account/supplier"
      enterable
      creatable
      @click-create="showModalCreate = true"
    >
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" />
        </td>
      </template>
      <template #wallet="{ item }">
        <td>
          <SMessageWallet :data="item" />
        </td>
      </template>
      <template #balance="{ item }">
        <td>
          <TMessageMoney :amount="item.balance" :currency="item.currency_id" />
        </td>
      </template>
      <template #amount_available_for_payment="{ item }">
        <td>
          <TMessageMoney
            :amount="item.amount_available_for_payment"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #deposit="{ item }">
        <td>
          <TMessageMoney :amount="item.deposit" :currency="item.currency_id" />
        </td>
      </template>
      <template #service="{ item }">
        <td>
          <TMessageMoney :amount="item.service" :currency="item.currency_id" />
        </td>
      </template>
      <template #updated_at="{ item }">
        <td>
          <TMessageDateTime :content="item.updated_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText :value.sync="filter.id" @update:value="filterChange" />
      </template>
      <template #wallet-filter class="d-inline-flex">
        <div class="d-inline-flex w-100">
          <SSelectSupplier
            :value.sync="filter.walletable_id"
            @change="filterChange"
            noCustomLabel
            prependAll
            class="w-100"
          />
          <SSelectCurrency
            :value.sync="filter.currency_id"
            @change="filterChange"
            class="w-75"
          />
        </div>
      </template>
      <template #updated_at-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setCreatedAtFilter"
        />
      </template>
    </TTableAdvance>
    <Modal v-if="showModalCreate" :show.sync="showModalCreate" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "../components/CreateWalletModal.vue";
export default {
  components: { Modal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "wallet",
          label: "Wallet",
          _style: "width: 400px; min-width: 400px",
          _classes: "text-wrap",
        },
        { key: "balance", label: "Balance", sorter: true },
        {
          key: "amount_available_for_payment",
          label: "Available payment",
          sorter: true,
        },
        {
          key: "updated_at",
          sorter: true,
          label: "Updated at",
          _style: "width: 250px",
        },
      ],
      showModalCreate: false,
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  created() {
    this.$store.dispatch("accounting.wallets_suppliers.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      wallets: "accounting.wallets_suppliers.list",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch(
        "accounting.wallets_suppliers.apply-query",
        filterFields
      );
    },
    setCreatedAtFilter({ start, end }) {
      this.filter_between["updated_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
  },
};
</script>
